@use "sass:math";

@import '../utils/_mixins';
@import "/bower_components/Skeleton/css/skeleton.css";

$notoSansFont: "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontSC: "Noto Sans SC", "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontTC: "Noto Sans TC", "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontJP: "Noto Sans JP", "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontKR: "Noto Sans KR", "Noto Sans", Arial, Helvetica, sans-serif;

$black: #000000;
$pcRed: #d30000;
$ps4Blue: #034169;
$xb1Green: #005400;
$blogPurple: #730D6A;
$steamGrey: #212121;
$white: #ffffff;

html {
    font-size: unset !important;
}

.clear {
    clear: both;
}
.recaptcha-disclaimer {
    margin: 1em 0;
    font-size: 0.75rem;
}

#btnLogin {
    color: #fff;
    font-size: 1rem;
}

.wrapper {
    margin-top: 0 !important;
}

.push{
    display: none;
}

.lightboxForm .alert {
    position: relative;
    margin: 0 auto;
    left: 0;
    top: 0;
    transform: none;
}

a.signupPlat {
    text-decoration: none;
}

.alert {
    @include center(true, false);
    position: fixed;
    z-index: 10;
    width: 500px;
    padding: 20px;
    background: rgba(255,255,255,0.9);
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    top: 110px;

    .close{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        color: #000;
        font-size: 17px;
    }

    li{
        list-style: none;
        margin-bottom: 4px;
    }

    ul,li{
        margin-bottom: 0;
    }

    &.alert-success {
        color: #6DB4CB;
        border-bottom: 4px solid #6DB4CB;
    }
    &.alert-danger {
        color: $pcRed;
        border-bottom: 4px solid $pcRed;
    }

    @include mq(tablet) {
        width:100%;
        top: 131px;
        text-align: center;
    }
}

body, html {
    height: auto;
}

body {
    background: url("/images/longlanding/mobileBg.jpg");
    background-size: cover;
    margin: 0;
    overflow-x: hidden;
    width: 100vw;

    font-family: $notoSansFont;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;

    @media screen and (min-width: 36em){
        background: url("/images/longlanding/desktopBg.jpg") center no-repeat;
        background-size: cover;
    }

    &.lang-zh-hans {
        font-family: $notoSansFontSC;
        textarea, input {
            font-family: $notoSansFontSC;
        }
    }

    &.lang-zh-hant {
        font-family: $notoSansFontTC;
        textarea, input {
            font-family: $notoSansFontTC;
        }
    }

    &.lang-ja {
        font-family: $notoSansFontJP;
        textarea, input {
            font-family: $notoSansFontJP;
        }
    }

    &.lang-ko {
        font-family: $notoSansFontKR;
        textarea, input {
            font-family: $notoSansFontKR;
        }
    }
}

textarea, input {
    font-family: $notoSansFont;
    font-weight: 400;
}

p {
    font-size: 12px;
    //font-family: 'open sans';

    @media screen and (min-width: 36em){
        font-size: 15px;
    }
    @media screen and (min-width: 90em){
        font-size: 18px;
    }
    @media screen and (min-width: 125em){
        font-size: 22px;
    }
    @media screen and (min-width: 160em){
        font-size: 26px;
    }
}

h1, h2, h3, a {
    //font-family: $notoSansFont;
}

h1 {
    margin-top: 0;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.title {
    width: 80%;
    margin: 0 auto;
    z-index: 2;

    @media screen and (min-width: 48em){
        margin: 0;
        width: 50%;
    }
    @media screen and (min-width: 90em){
        width: 40%;
    }

    p {
        text-align: center;
        color: #bababa;
        text-shadow: 0px 5px 20px #000;
        line-height: 120%;
        margin-top: 2%;
    }

    h1 {
        color: #fff;
        padding: 5px;
        border: 1px solid #ec1813;
        font-size: 11px;
        text-align: center;

        @media screen and (min-width: 23.4375em){
            font-size: 13px;
        }
        @media screen and (min-width: 26.5625em){
            font-size: 15px;
        }
        @media screen and (min-width: 36em){
            font-size: 20px;
        }
        @media screen and (min-width: 48em){
            font-size: 17px;
        }
        @media screen and (min-width: 64em){
            border: 1.5px solid #ec1813;
            font-size: 20px;
        }
        @media screen and (min-width: 90em){
            font-size: 25px;
        }
        @media screen and (min-width: 125em){
            font-size: 30px;
        }
        @media screen and (min-width: 160em){
            font-size: 40px;
        }
    }
}

.left::before,.right::before{
    content: "\00a0";
    display: block;
    background-color: #ec1813;
    height: 1px;
    position: relative;

    @media screen and (min-width: 36em){
        top: 18px;
    }
    @media screen and (min-width: 48em){
        top: 16px;
    }
    @media screen and (min-width: 64em){
        top: 18px;
        height: 2px;
    }
    @media screen and (min-width: 90em){
        right: 50%;
        top: 21px;
    }
    @media screen and (min-width: 160em){
        top: 27px;
        right: 50%;
    }
}

.left{
    @media screen and (min-width: 48em){
        float: left;
        margin-left: 7% !important;
    }
    @media screen and (min-width: 90em){
        margin-left: 15%;
    }

    &::before {
        width: 15%;
        right: 15%;
        top: 13px;

        @media screen and (min-width: 26.5625em){
            top: 15px;
        }
        @media screen and (min-width: 90em){
            width: 18%;
            right: 18%;
            top: 22px;
        }
        @media screen and (min-width: 125em){
            top: 25px;
        }
        @media screen and (min-width: 160em){
            top: 30px;
        }
    }
}

.right{
    @media screen and (min-width: 48em){
        float: right;
        margin-right: 7%;
    }
    @media screen and (min-width: 90em){
        margin-right: 15%;
    }

    &::before {
        width: 12.5%;
        left: 100%;
        top: 12px;

        @media screen and (min-width: 26.5625em){
            top: 14px;
        }
        @media screen and (min-width: 48em){
            width: 14%;
        }
        @media screen and (min-width: 90em){
            width: 37.5%;
            top: 18px;
        }
        @media screen and (min-width: 125em){
            top: 22px;
        }
        @media screen and (min-width: 160em){
            width: 37.5%;
        }
    }
}

.nav {
    overflow: hidden;
    background-color: #fff;
    width: 100vw;
    position: fixed;
    height: 40px;
    z-index: 11;
    top: -20%;

    @media screen and (min-width: 36em){
        top: -20%;
        height: 50px;
    }
    @media screen and (min-width: 64em){
        height: 60px;
    }
    @media screen and (min-width: 90em){
        height: 70px;
    }
    @media screen and (min-width: 125em){
        height: 75px;
    }
}

.fullLogo {
    display: none;

    @media screen and (min-width: 36em){
        margin: 0 auto;
        height: 75%;
        display: inline;
        vertical-align: middle;
        padding-top: 5px;
        margin-top: 3px;
    }
    @media screen and (min-width: 64em){
        margin-top: 3px;
        height: 95%;
        margin-left: 25%;
    }
    @media screen and (min-width: 90em){
        margin-top: 8px;
        height: 100%;
    }
    @media screen and (min-width: 125em){
        height: 110%;
        margin-left: 50%;
        margin-top: 10px;
    }
}

.navLogo {
    height: 50px;
    float: left;
    text-align: center;
    margin-left: 29%;

    @media screen and (min-width: 36em){
        margin-left: 30%;
    }
    @media screen and (min-width: 48em){
        margin-left: 44%;
    }
    @media screen and (min-width: 64em){
        margin-left: 42%;
    }
    @media screen and (min-width: 90em){
        margin-left: 43%;
    }
    @media screen and (min-width: 160em){
        margin-left: 46%;
    }
    @media screen and (min-width: 107.5em){
        margin-left: 44%;
    }
    @media screen and (min-width: 125em){
        margin-left: 45%;
    }
}

.icon {
    margin: 0 auto;
    height: 86%;

    @media screen and (min-width: 36em){
        display: none;
    }
}

.navSignup {
    float: right;
    font-size: 8px;
    text-decoration: none;
    color: #fff;
    padding-top: 15px;
    background-color: #ec1813;
    height: 100%;
    width: auto;
    white-space: nowrap;
    text-align: center;
    padding-left: 1px;
    padding-right: 1px;
    cursor: pointer;

    @media screen and (min-width: 23.4375em){
        font-size: 9px;
        padding-top: 14px;
    }
    @media screen and (min-width: 26.5625em){
        font-size: 10px;
    }
    @media screen and (min-width: 36em){
        font-size: 12px;
        padding-top: 19px;
        &:hover {
            text-shadow: 0 0 10px #fff;
            box-shadow: 0 0 50px #ec1813;
            color: #fff;
        }
    }
    @media screen and (min-width: 48em){
        font-size: 12px;
        padding:19px 30px 0 20px;
    }
    @media screen and (min-width: 64em){
        padding-top: 21px;
        font-size: 15px;
    }
    @media screen and (min-width: 90em){
        font-size: 17px;
        padding-top: 26px;
    }
    @media screen and (min-width: 107.5em){
        font-size: 19px;
        padding-top: 25px;
    }
    @media screen and (min-width: 125em){
        font-size: 24px;
        padding-top: 25px;
    }
}

.link-to-site {
    position: fixed;
    top: 0px;
    background-color: #000;
    width: 100%;
    color: #fff;
    z-index: 401;
    display: block;
    padding: 6px 5px 3px 5px;
    margin: 0;

    .continue {
        float: left;
        font-size: 10px;
        text-decoration: none;
        color: #fff;
        height: 100%;
        line-height: 1;
        text-align: center;
        //font-family: 'open sans';
        margin-top: 1px;

        @media screen and (min-width: 26.5625em){
            font-size: 11px;
        }
        @media screen and (min-width: 36em){
            font-size: 12px;
            padding-left: 4px;
        }
        @media screen and (min-width: 90em){
            font-size: 13px;
            padding-top: 2px;
        }

        &:hover {
            color: #ec1813;
        }
    }
}

#languages {
    font-size: 9px;
    margin: 0 auto;
    text-decoration: none;
    color: #fff;
    list-style: none;
    float: right;
    font-family: 'open-sans';

    @media screen and (min-width: 36em){
        font-size: 11px;
    }
    @media screen and (min-width: 90em){
        font-size: 13px;
    }

    li {
        position: relative;
        padding-right: 6px;
        background-color: black;
        list-style: none;
        margin: 0;

        ul {
            position: absolute;
            top: 100%;
            left: -12px;
            z-index: 401;
            padding: 3px;
            margin: 0;
            line-height: 130%;

            li {
                list-style: none;
                background-color: #000;
                text-align: left;
                padding-left: 10px;
            }
        }

        a {
            color: #fff;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -6em;
                margin-top: -12px;
                height: 0;
                width: 0;
                border: 5px solid transparent;
                border-top-color: #dFeEFF;
                border-top-color: rgba(255,255,255,.5);
            }
        }
    }
}

#splashpage #sign-up {
    background:none;
}

.darkOverlay {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    background-color:rgba(0,0,0,0.8);
    display: none;
    z-index: 500;
    overflow-y: scroll;
    height: 100%;

    .lightboxForm {
        //font-family: 'Open Sans', sans-serif;
        margin: 20% auto 0;

        img {
            width: 100%;
        }

        h1 {
            display: none;
        }

        @media screen and (min-width: 48em){
            margin-top: 0;
        }

        &.contestEnter,&.contestOver{
            color:#fff;
            margin-top: 20% !important;

            img.logoWhite{
                margin-bottom: 30px;
            }

            p{
                margin-bottom: 0;
                text-align: center;
                font-size: 22px;
            }

            a{
                color:$pcRed;
                font-family: inherit;
            }

            form {
                text-align: center;
                input[type="checkbox"]{
                    margin-bottom: 0;
                }
                input[type="email"]{
                    //@include border-radius(0);
                    border-radius: 0;
                    width: 100%;
                    background: transparent;
                    border-left: 0;
                    border-top: 0;
                    border-right: 0;
                    margin: 20px 0;
                }
            }
        }

        &.contestOver{
            .submit-btn{
                text-align: center;
                display: block;
                margin: 20px;

                a{
                    color:#fff;
                    border:none;
                    &:hover{
                        color:#fff;
                    }
                }
            }
        }

        .highlightCheckbox{
            //@include border-radius(30px);
            border-radius: 30px;
            border: 1px solid #fff;
            display: inline-block;
            margin: 20px 0;
            padding: 20px;
            font-size: 18px;
        }

        .readRulesBox{
            width: 60%;
            margin: 0 auto;
            margin-bottom: 30px;

            @include mq(tablet){
                width:100%;
            }
        }

        .signupContent {
            width: 100%;
            color:white;
            top:0;

            @include platform-selector;

            .recaptcha-check {
                width: 310px;
                margin: 20px auto;
            }

            fieldset#opt-in-newsletter,fieldset#agree,fieldset#contest,fieldset#read-rules{
                width:80%;
                margin: 0 auto;
                margin-bottom: 8px;
                line-height: 18px;
            }
            input::-webkit-input-placeholder{
                color:white;
            }
            input[type="text"],input[type="email"],input[type="password"]{
                background: transparent;
                border: none;
                border-bottom: 1px solid white;
                color: #fff;
                border-radius: 0 !important;
            }
        }
    }
}

.container {
    padding: 0;
    width: 90%;
}

#submit-btn button,.submit-btn {
    @include button('.button-wrap,.button,input[type="submit"]');
}

.content {
    padding-top: 20px;
}

#submit-btn{
    text-align: center;

    .button-wrap{
        transform: skewX(0deg);
    }
}

.consoleLoginBtns{
    text-align: center;

    a{
        width: 32%;
        height: 45px;
        padding-top: 8px;
        min-width: 130px;
        display: inline-block;
        text-align: center;

        img{
            width: auto !important;
            margin-top: 6px;
        }

        @media (max-width: 600px){
            width:100%;
        }

        &.ps4Btn{
            background-color: #274368;
        }
        &.xb1Btn{
            background-color: #2f5212;
        }
        &.swiBtn{
            background-color: #e70012;
            position: relative;
            top: 1px;
            margin-bottom: 50px;
        }
    }
}

form{

    fieldset label.error {
        color: red;
        margin-bottom: 10px;
        font-weight: 100;
    }
    #email label,#email-login label,#confirm-email label,#alias label,#password label,#password-login label,#confirm-password label{
        display: none;
    }

    #opt-in-newsletter label,#contest label,#agree label,#read-rules label{
        display: inline;
        margin-bottom: .5rem;
        font-weight: 100;
        a
        {
            color: #fff;
            text-decoration: underline;
            font-family: inherit;
            text-decoration-color: #fff;
        }
    }

    #opt_in_newsletter_check {
        margin-bottom: 0;
    }

    #agreed {
        margin-bottom: 0 !important;
    }
}

input.error {
    border: 1px solid red !important;
}

.consoleLines{
    margin: 15px 0;

    .contentHeaderText{
        color:white;
        text-align: center;
        position: relative;
        top: -17px;
        text-transform: uppercase;
        font-size: 24px;
    }

    &.contentHeaderContain .contentHeaderLines{
        border-top: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
        width: 22% !important;
        height: 3px;
        float: left;

        @include mq(tablet){
            display: none;
        }
    }

    .contentLineRight
    {
        float: right !important;
    }
}

.section1 {
    background: url("/images/longlanding/mobile-masthead-short.jpg") no-repeat top;
    background-size: contain;
    text-align: center;
    z-index: 10;
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    @media screen and (min-width: 40em){
        background-size: cover;
    }

    @media screen and (min-width: 48em){
        background: none !important;
    }

    &.tennocon2019{
        &.cgi{
            background: url("/images/longlanding/tennocon2019/mobile-bg.png") no-repeat center;
            background-size: cover;

            @media screen and (min-width: 36em){
                background: url("/images/longlanding/tennocon2019/tablet-bg.png") no-repeat center;
                background-size: cover;
            }
        }
        &.railj{
            background: url("/images/longlanding/tennocon2019/railjack-mobile-bg.png") no-repeat center;
            background-size: cover;

            @media screen and (min-width: 36em){
                background: url("/images/longlanding/tennocon2019/railjack-tablet-bg.png") no-repeat center;
                background-size: cover;
            }
        }

        //.pageTurn{
        //    position: absolute;
        //    top: 30px;
        //    right: 17px;
        //    width: 504px;
        //    height: 321px;
        //    background-image: url('../images/longlanding/tennocon2019/contest-triangle.png');
        //    z-index: 55;
        //
        //    .text{
        //        position: absolute;
        //        color:#fff;
        //        text-transform: uppercase;
        //        font-size: 22px;
        //        right: 30px;
        //        line-height: 28px;
        //        top: 20px;
        //        font-weight: bold;
        //        font-family: inherit;
        //
        //        span{
        //            color: $pcRed;
        //        }
        //        .bigger{
        //            font-weight: bold;
        //            font-size: 30px;
        //        }
        //    }
        //}

        .logoSlogan {
            margin-bottom: 20px !important;
            .text{
                display: block;
                color: #fff;
                text-decoration: none;
                font-family: inherit;
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                @media screen and (min-width: 36em){
                    font-size: 21px;
                }
                @media screen and (min-width: 56em){
                    font-size: 23px;
                }
                @media screen and (min-width: 64em){
                    font-size: 22px;
                }
                @media screen and (min-width: 90em){
                    font-size: 25px;
                }
                @media screen and (min-width: 125em){
                    font-size: 34px;
                }
                @media screen and (min-width: 160em){
                    font-size: 42px;
                }

                span{
                    color: $pcRed;
                }
            }

            img{
                width:90%;
                @media screen and (min-width: 26.5625em){
                    width: 80%;
                }
                @media screen and (min-width: 36em){
                    width: 70%;
                }
                @media screen and (min-width: 48em){
                    width: 45%;
                }
                @media screen and (min-width: 64em){
                    width: 35%;
                }
                @media screen and (min-width: 90em){
                    width: 25%;
                }
            }
        }
        .lensFlare{
            margin-top: 1%;
            @media screen and (min-width: 48em){
                margin-top: 2%;
            }
        }
        .playFreeNow{
            display: inline-block;
            margin: 0 auto;
            font-size: 14px;
            @media screen and (min-width: 36em){
                font-size: 21px;
            }
            @media screen and (min-width: 90em){
                font-size: 22px;
            }
            @media screen and (min-width: 125em){
                font-size: 30px;
            }
        }
        .haveAccount{
            margin-top: 5px;
            display: block;
            font-size: 11px;
            height: auto;
            //font-family: 'Open Sans',sans-serif;
            color:#fff;

            @media screen and (min-width: 36em){
                font-size: 13px;
            }
            @media screen and (min-width: 90em){
                font-size: 14px;
                margin-top: 2px;
            }
            @media screen and (min-width: 125em){
                font-size: 18px;
                margin-top: 3px;
            }
        }
    }
}

.logoSlogan {
    margin-bottom: 30px;

    @media screen and (min-width: 48em){
        margin-bottom: 16px;
        z-index: 50;
        position: inherit;
    }
    @media screen and (min-width: 90em){
        margin-bottom: 30px;
    }
    @media screen and (min-width: 125em){
        margin-bottom: 43px;
    }
    @media screen and (min-width: 160em){
        margin-bottom: 50px;
    }

    img {
        width: 80%;
        margin-top: 50vh;

        @media screen and (min-width: 36em){
            width: 60%;
        }
        @media screen and (min-width: 48em){
            width: 50%;
            margin-top: 26%;
        }
        @media screen and (min-width: 64em){
            width: 35%;
        }
        @media screen and (min-width: 90em){
            margin-top: 26%;
        }
        @media screen and (min-width: 140em){
            width: 25%;
        }
    }

    &.static {
        z-index: 0 !important;
        margin-bottom: 30px;
        width: 100%;

        @media screen and (min-width: 48em){
            z-index: 50;
            position: inherit;
        }

        img {
            width: 75%;
            margin-top: 10%;

            @media screen and (min-width: 36em){
                width: 55%;
                margin-top: 5%;
            }
            @media screen and (min-width: 48em){
                width: 50%;
                &.long {
                    width: 30%;
                }
            }
            @media screen and (min-width: 72em){
                width: 30%;
            }
            @media screen and (min-width: 120em){
                margin-top: 2%;
            }
        }
    }
}

.playFreeNow {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 35px;
    background-color: #bf120f;
    //@include transition(box-shadow .5s, text-shadow .5s);
    transition: box-shadow .5s, text-shadow .5s;
    cursor: pointer;

    @media screen and (min-width: 36em){
        font-size: 21px;
        padding: 10px 60px 10px 60px;
    }
    @media screen and (min-width: 48em){
        font-size: 21px;
        padding: 7px 45px 7px 45px;
    }
    @media screen and (min-width: 56em){
        padding: 7px 55px 7px 55px;
    }
    @media screen and (min-width: 64em){
        padding: 8px 70px 8px 70px;
    }
    @media screen and (min-width: 90em){
        font-size: 29px;
        padding: 10px 80px 10px 80px;
    }
    @media screen and (min-width: 125em){
        font-size: 28px;
        padding: 15px 120px 15px 120px;
    }
    @media screen and (min-width: 160em){
        font-size: 34px;
        padding: 15px 130px 15px 130px;
    }

    &:hover {
        text-shadow: 0 0 10px #fff;
        box-shadow: 0 0 50px #ec1813;
        color: #fff;
    }
}

.haveAccount {
    text-decoration: none;
    color: #fff;
    font-size: 9px;
    display: block;
    margin-top: 20px;
    height: auto;
    margin-bottom: 15px;

    @media screen and (min-width: 36em){
        font-size: 14px;
        margin-top: 7px;
        margin-bottom: 4%;
    }
    @media screen and (min-width: 90em){
        font-size: 16px;
        margin-top: 11px;
        margin-bottom: 2%;
    }
    @media screen and (min-width: 125em){
        font-size: 16px;
        margin-top: 15px;
    }
    @media screen and (min-width: 160em){
        font-size: 21px;
        margin-top: 20px;
        margin-bottom: 1%;
    }

    &:hover {
        color: #ec1813;
    }
}

.watchTrailer {
    text-align: center;
    margin-top: 15px;

    @media screen and (min-width: 36em){
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 48em){
        display: none;
    }

    a {
        text-decoration: none;
        font-size: 20px;
        color: #fff;
    }
    img {
        vertical-align: bottom;
        margin-right: 5px;
    }
}

.gameDesc {
    color: #fff;
    width: 80%;
    margin: 0 auto;
    clear: both;
    font-weight: bold;
    line-height: 120%;
    z-index: 11;
    position: relative;

    text-shadow: 0 0 3px #000;

    @media screen and (min-width: 48em){
        line-height: 125%;
        width: 56%;
        margin-top: -20px;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 56em){
        width: 47%;
    }
    @media screen and (min-width: 77em){
        width: 46%;
    }
    @media screen and (min-width: 90em){
        width: 41%;
        margin-top: -10px;
        margin-bottom: 30px;
        line-height: 174%;
    }
    @media screen and (min-width: 125em){
        line-height: 200%;
    }
    @media screen and (min-width: 160em){
        margin-top: -10px;
        margin-bottom: 40px;
        line-height: 222%;
    }

    .gameDescCopy {
        margin: 10px auto 0;
        width: 100%;

        p {
            margin-bottom: 0;
        }

        @media screen and (min-width: 160em){
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}

.scrollIndicator {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(165deg) brightness(102%) contrast(100%);
    animation: bounce 1.5s infinite ease-in-out;
    @keyframes bounce {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(5px);
        }

        100% {
            transform: translateY(0);
        }
    }
}

.lightbox {
    background-color: rgba(0,0,0,0.8);;
    z-index: 450;
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    display: none;

    iframe {
        border: none;
        margin-top: 50%;
    }
}

.close {
    margin-top: 3%;
    text-align: left;
    cursor: pointer;
    margin-left: 4%;

    @media screen and (min-width: 36em){
        margin-top: 2%;
        margin-left: 3%;
    }
    @media screen and (min-width: 90em){
        margin-top: 1.5%;
        margin-left: 2%;
    }

    img {
        width: 5%;
        vertical-align: middle;
        margin-right: 3px;

        @media screen and (min-width: 36em){
            width: 3%;
        }
        @media screen and (min-width: 64em){
            width: 2%;
        }
        @media screen and (min-width: 90em){
            width: 1.8%;
        }
        @media screen and (min-width: 125em){
            width: 1%;
        }
    }
    p {
        color: #fff;
        display: inline;
        font-size: 15px;
        vertical-align: middle;

        @media screen and (min-width: 90em){
            font-size: 18px;
        }
    }
}

.keyartItems {
    cursor: default;
    display: none;

    @media screen and (min-width: 48em){
        display: block;
    }
}

.gradient {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, transparent 30%, black 100%);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.videoBackground {
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100vw;
    height: 100vh;
}

@media (min-aspect-ratio: 16/9) {
    .videoBackground {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
    }
}
@media (max-aspect-ratio: 16/9) {
    .videoBackground {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
    }
}

.videoPlayer {
    display: none;
    //pointer-events: none;

    @media screen and (min-width: 48em){
        display: inline;
        position: relative;
        overflow: hidden;
        width: 600px;
        height: 337px;
    }
    @media screen and (min-width: 64em){
        width: 820px;
        height: 461px;
    }
    @media screen and (min-width: 90em){
        width: 1065px;
        min-height: 600px;
    }
    @media screen and (min-width: 120em){
        width: 1519px;
        height: 853px;
    }
    @media screen and (min-width: 125em){
        width: 1570px;
        height: 884px;
    }
    @media screen and (min-width: 160em){
        width: 2050px;
        height: 1155px;
    }
}

.playButton {
    z-index: 50;
    position: inherit;
}

@media screen and (min-width: 48em){

    .keyartShip1 {
        position: absolute;
        margin-left: 19%;
        width: 34%;
        margin-top: -48%;
        //@include animation-name(move1);
        //@include animation-direction(reverse);
        //@include animation-duration(6s);
        //@include animation-timing-function(linear);
        //@include animation-fill-mode(forwards);
        animation-name: move1;
        animation-direction: reverse;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        img {
            width: inherit;
        }
    }

    @keyframes move1 {
        from {margin-left: 19%; margin-top: -48%;}
        to {margin-left: 16%; margin-top: -49%;}
    }

    .keyartShip2 {
        position: absolute;
        margin-left: 10%;
        width: 39%;
        margin-top: -44%;
        //@include animation-name(move2);
        //@include animation-direction(reverse);
        //@include animation-duration(6s);
        //@include animation-timing-function(linear);
        //@include animation-fill-mode(forwards);
        animation-name: move2;
        animation-direction: reverse;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        img {
            width: inherit;
        }
    }

    @keyframes move2 {
        from {margin-left: 10%; margin-top: -44%;}
        to {margin-left: 7%; margin-top: -45%;}
    }

    .keyartShip3 {
        position: absolute;
        margin-left: 63%;
        width: 32%;
        margin-top: -27%;
        //@include animation-name(move3);
        //@include animation-direction(reverse);
        //@include animation-duration(6s);
        //@include animation-timing-function(linear);
        //@include animation-fill-mode(forwards);
        animation-name: move3;
        animation-direction: reverse;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        img {
            width: inherit;
        }
    }

    @keyframes move3 {
        from {margin-left: 60%; margin-top: -22%;}
        to {margin-left: 63%; margin-top: -25%;}
    }

    .lensFlare {
        position: absolute;
        top: 0;
        z-index: 2;
        margin-top: 3%;
        width: 100%;
        //@include animation-name(glow);
        //@include animation-direction(alternate-reverse);
        //@include animation-duration(6s);
        //@include animation-iteration-count(infinite);
        animation-name: glow;
        animation-direction: alternate-reverse;
        animation-duration: 6s;
        animation-iteration-count: infinite;

        img {
            width: inherit;
        }
    }

    @keyframes glow {
        from {opacity: 1;}
        to {opacity: 0.95;}
    }

    .smoke {
        width: 100%;
        mix-blend-mode: screen;
        opacity: 0.35;
        margin-top: 5%;
        position: absolute;
        left: 0;
    }
}

.keyartCharacters{
    width: 180%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 48em){
        width: 50%;
    }

    @media screen and (min-width: 70em){
        width: 60%;
    }

    @media screen and (min-aspect-ratio: 35/18){
        transform: translate(-50%, -13%) !important;
        width: 55% !important;
    }

    &.short {

        transform: translate(-50%, -20%);
        width: 140%;

        @media screen and (min-width: 36em){
            transform: translate(-50%, -25%);
            width: 120%;
        }

        @media screen and (min-aspect-ratio: 3/2){
            transform: translate(-50%, -42%);
            width: 60%;
        }

        @media screen and (min-width: 130em){
            width: 60%;
        }
    }

    .leftWarframe{
        width: 21%;
        margin-right: 2%;
        margin-bottom: -5%;
        transform: translate(-35px, -40px) scale(1.03);
        @media screen and (max-width: 48em) {
            transform: translate(-5px, -40px) scale(1.03);
        }
        @media screen and (min-width: 60em) {
            transform: none;
            transition: all 3s ease-out;
        }
        &.stage1 {
            transform: translate(-35px, -40px) scale(1.03);
        }
    }

    .centerWarframe{
        width: 25%;
        margin-top: -20%;
        transform: translate(0px, -50px) scale(1.03);
        @media screen and (max-width: 48em) {
            transform: translate(0px, -50px) scale(1.03);
        }
        @media screen and (min-width: 60em) {
            transform: none;
            transition: all 3s ease-out;
        }
        &.stage1 {
            transform: translate(0, -50px) scale(1.05);
        }
    }

    .rightWarframe{
        width: 17%;
        margin-bottom: -5%;
        transform: translate(30px, -40px) scale(1.03);
        @media screen and (max-width: 48em) {
            transform: translate(0, -40px) scale(1.03);
        }
        @media screen and (min-width: 60em) {
            transform: none;
            transition: all 3s ease-out;
        }
        &.stage1 {
            transform: translate(30px, -40px) scale(1.03);
        }
    }
}

.keyart {
    display: none;
    @media screen and (min-width: 48em){
        object-fit: cover;
        display: block;
        width: 100vw;
        position: absolute;
        top: -3px;
        left: 0;
        z-index: -1;
    }


    img {
        width: inherit;
        display: block;
    }
}

.section2 {
    overflow-x: hidden;
    margin-top: 65px;
    clear: both;

    @media screen and (min-width: 36em){
        margin-top: 85px;
    }

    @media screen and (min-width: 48em){
        background: none;
        clear: both;
        height: auto;
        margin-top: 50px;
    }
    @media screen and (min-width: 64em){
        margin-top: 50px;
    }

    .title {
        position: absolute;
        margin-left: 10%;
        z-index: -5;

        @media screen and (min-width: 48em){
            margin-top: 5%;
            z-index: 9;
        }
        @media screen and (min-width: 160em){
            margin-top: 7%;
        }
    }
}

section {
    .title {
        p {
            color: #fff;
            text-shadow: 0 0 3px #000;
        }
    }
}

.gallery {
    display: none;

    @media screen and (min-width: 48em){
        margin-top: 22%;
        display: block;
        overflow: hidden;
    }
    @media screen and (min-width: 64em){
        margin-top: 11%;
    }
}

.transitionTop {
    display: none;

    @media screen and (min-width: 48em){
        position: absolute;
        width: 100%;
        z-index: 1;

        img {
            width: 100%;
            margin-top: -15%;
        }
    }
}

.transitionBot {
    display: none;

    @media screen and (min-width: 48em){
        display: block;
        position: absolute;
        width: 100%;

        img {
            width: 100%;
            margin-top: -8%;
        }
    }
    @media screen and (min-width: 215em){
        img{
            margin-top: -3%;
        }
    }

    @media screen and (min-width: 240em){
        img{
            margin-top: 4%;
        }
    }
}

.galleryMobile {
    margin-top: 5%;

    @media screen and (min-width: 48em){
        display: none;
    }
}

.frameMobile {
    text-align: center;
    width: 100%;
    margin-top: 130px;

    @media screen and (min-width: 26.5625em) {
        margin-top: 100px;
    }

    img {
        width: 75%;
        display: inline;
        margin-bottom: -6px;

        @media screen and (min-width: 36em) {
            width: 50%;
        }
    }
}

.warframeInfo {
    background-color: rgba(0, 0, 0, 0.3);
    width: 90%;
    margin-left: 5%;
    z-index: 9;

    @media screen and (min-width: 26.5625em) {
        width: 80%;
        margin-left: 10%;
    }
    @media screen and (min-width: 36em){
        width: 66%;
        margin-left: 17%;
    }
    @media screen and (min-width: 48em){
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        width: 45%;
        padding: 8px 12px 8px 12px;
        float: right;
        margin-top: -25%;
        left: 41%;
        color: #fff;
        margin-left: 10%;

        .warframeTitle {
            color: #ec1813;
            margin: 0;
            font-size: 15px;
        }
    }
    @media screen and (min-width: 64em){
        width: 40%;
        margin-top: -25%;

        .warframeTitle {
            font-size: 18px;
        }
    }
    @media screen and (min-width: 77em){
        width: 33%;
        left: 50%;
    }
    @media screen and (min-width: 90em){
        left: 48%;
        margin-top: -21%;

        .warframeTitle {
            font-size: 23px;
        }
    }
    @media screen and (min-width: 125em){
        .warframeTitle {
            font-size: 25px;
        }
    }
    @media screen and (min-width: 160em){
        width: 29%;
        left: 52%;
        margin-top: -19%;

        .warframeTitle {
            font-size: 40px;
        }
    }
    @media screen and (min-width: 215em){
        margin-top: -13%;
    }
    @media screen and (min-width: 240em){
        margin-top: -6%;
    }
}

.warframeTitleMobile {
    color: #ec1813;
    margin-bottom: 0px;
    padding-top: 5px;
    text-transform: uppercase;

    h1 {
        margin-bottom: 10px;
    }
}

.warframeDesc {
    color: #fff;
    margin: 0;
    padding: 8px;
    line-height: 16px;

    @media screen and (min-width: 26.5625em){
        padding: 13px;
    }
    @media screen and (min-width: 48em){
        margin: 7px 0 5px 0;
        font-size: 13px;
        padding: 0;
    }
    @media screen and (min-width: 90em){
        font-size: 15px;
    }
    @media screen and (min-width: 125em){
        font-size: 17px;
    }
    @media screen and (min-width: 160em){
        font-size: 22px;
        line-height: 24px;
    }
    @media screen and (min-width: 215em){
        font-size: 27px;
        line-height: 36px;
    }
}

.swipeArrows {
    position: absolute;
    width: 100%;
    margin-top: 65%;

    @media screen and (min-width: 36em){
        margin-top: 50%;
    }
    @media screen and (min-width: 48em){
        display: none;
    }
}

.swipeLeft {
    float: left;
    margin-left: 4%;
}

.swipeRight {
    float: right;
    margin-right: 4%;
}

.spaceOverlay,.fadeOverlay {
    display: none;

    @media screen and (min-width: 48em){
        position: absolute;
        display: block;

    }
}

.spaceOverlay{
    @media screen and (min-width: 48em){
        width: 100vw;
        top: 0;
        z-index: 9;
        margin-top: 107%;
        mix-blend-mode: lighten;
        opacity: 0.42;
        pointer-events: none;

        img{
            width: 100vw;
        }
    }
    @media screen and (min-width: 64em){
        margin-top: 71%;
    }
}

.fadeOverlay{
    @media screen and (min-width: 48em){
        background-color: #000;
        z-index: 0;
        width: 100vw;
        height: 400px;
        opacity: 0;
    }
    @media screen and (min-width: 56em){
        height: 467px;
    }
    @media screen and (min-width: 64em){
        height: 535px;
    }
    @media screen and (min-width: 77em){
        height: 642px;
    }
    @media screen and (min-width: 90em){
        height: 742px;
    }
    @media screen and (min-width: 107.5em){
        height: 895px;
    }
    @media screen and (min-width: 125em){
        height: 1052px;
    }
    @media screen and (min-width: 142.5em){
        height: 1180px;
    }
    @media screen and (min-width: 160em){
        height: 1322px;
    }
}

.videoContainer{
    @media screen and (min-width: 48em){
        height: 385px;

        .warframeVideo {
            width: 100%;
            display: block;
            opacity: 0;
            position: absolute;
        }
    }
    @media screen and (min-width: 56em){
        height: 432px;
    }
    @media screen and (min-width: 64em){
        height: 503px;
    }
    @media screen and (min-width: 77em) {
        height: 592px;
    }
    @media screen and (min-width: 90em){
        height: 717px;
    }
    @media screen and (min-width: 107.5em){
        height: 836px;
    }
    @media screen and (min-width: 125em){
        height: 936px;
    }
    @media screen and (min-width: 142.5em){
        height: 1103px;
    }
    @media screen and (min-width: 160em){
        height: 1250px;
    }
}

.videoFallback{
    @media screen and (min-width: 48em){
        width: 100%;
        position: absolute;
        z-index: 0;

        img {
            width: 100%;
        }
    }
}

.slider{
    @media screen and (min-width: 48em){
        margin-left: -25%;
        width: 100%;

        .slides {
            width: 130vw !important;
            height: 200px;
            padding: 0;
            margin-top: -11%;

            .slick-slide {
                //@include filter(grayscale(100%));
                filter: grayscale(100%);
                width:200px !important;

                > div{
                    width: 200px !important;
                }

                &:focus {
                    outline: none !important;
                }

                .frame {
                    width: 70% !important;
                    text-align: center;
                    height: 209px;
                    display: inline-block;
                    margin-left: -4%;

                    img {
                        width: 100%;
                    }

                    .warframeBox {
                        z-index: 5;
                        pointer-events: none;
                        width: 100%;
                        height: 45%;
                        margin-top: -94px;
                        border-bottom: 1px solid #fff;
                    }
                }

                &.slick-center {
                    //@include filter(grayscale(0%));
                    filter: grayscale(0%);
                }
            }
        }
    }
    @media screen and (min-width: 64em){
        .slides {
            margin-top: -14%;
            .slick-slide {

                .frame {
                    margin-left: -42%;

                    .warframeBox {
                        height: 60.5%;
                        margin-top: -125px;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 77em){
        .slides{
            .slick-slide {
                .frame {
                    margin-left: -84%;
                    margin-top: 12%;
                }
            }
        }
    }
    @media screen and (min-width: 90em){
        margin-left: -22%;

        .slides{
            .slick-slide {
                width: 230px !important;

                > div {
                    width: 212px !important;
                }

                .frame {
                    margin-left: -87%;
                    width: 85% !important;
                    margin-top: 5%;

                    .warframeBox {
                        margin-top: -109px;
                        height: 53%;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 125em){
        margin-left: -28%;

        .slides {
            position: absolute !important;
            width: 128vw !important;
            .slick-slide {

                .frame {
                    margin-left: -35%;
                    height: 211px;

                    .warframeBox {
                        margin-top: -140px;
                        height: 67%;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 160em){
        .slides {
            margin-top: -12%;
            width: 128vw !important;
            .slick-slide {

                .frame {
                    height: 439px;
                    margin-left: -58%;

                    .warframeBox {
                        margin-top: -148px;
                        height: 34%;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 215em){

        .slides {
            margin-top: -5%;
            .slick-slide {
                display: block;
                padding-right: 475px;
                .frame {
                    width: 180% !important;
                }
            }
        }
    }

    @media screen and (min-width: 240em){
        margin-top: 12%;
    }
}



.section3 {
    margin-top: 10px;
    clear: both;
    overflow-x: hidden;

    @media screen and (min-width: 48em){
        height: initial;
        background: none;
        position: relative;
        overflow: initial;
    }
    @media screen and (min-width: 90em){
        margin-top: 100px;
    }
    @media screen and (min-width: 125em){
        margin-top: 30px;
    }
    @media screen and (min-width: 215em){
        margin-top: 250px;
    }

    h1
    {
        font-size: 11px;

        @media screen and (min-width: 23.4375em){
            font-size: 11px;
        }
        @media screen and (min-width: 26.5625em){
            font-size: 12px;
        }
        @media screen and (min-width: 36em){
            font-size: 16px;
        }
        @media screen and (min-width: 48em){
            font-size: 13px;
        }
        @media screen and (min-width: 56em){
            font-size: 17px;
        }
        @media screen and (min-width: 90em){
            font-size: 19px;
        }
        @media screen and (min-width: 103.5em){
            font-size: 25px;
        }
        @media screen and (min-width: 160em){
            font-size: 34px;
        }
    }
}

.warframeGrineer {
    display: inline;

    .warframe {
        float: left;
        width: 80%;
        margin-top: 13%;
        opacity: 1;
        margin-left: 0%;

        @media screen and (min-width: 48em){
            width: 38%;
            margin-top: -4%;
            position: relative;
            opacity: 0;
            margin-left: 10%;
        }
        @media screen and (min-width: 90em){
            margin-top: 4%;
            margin-left: 12%;
            width: 33%;
        }
        @media screen and (min-width: 160em){
            margin-left: 250px;
        }
    }

    .grineer {
        float: right;
        width: 66%;
        margin-top: 5%;
        display: none;

        @media screen and (min-width: 48em){
            float: none;
            width: 47%;
            margin-top: 20%;
            position: absolute;
            margin-left: 52%;
            display: initial;
        }
        @media screen and (min-width: 90em){
            float: none;
            width: 39%;
            margin-top: 14%;
            position: absolute;
            margin-left: 11%;
        }
    }

    .parkourBg {
        display: block;
        float: left;
        width: 180%;
        position: relative;
        margin-top: -51%;
        margin-left: -118px;
        z-index: -5;

        @media screen and (min-width: 48em){
            right: 0;
            width: 100%;
            float: right;
            position: absolute;
            margin-top: 10%;
        }
        @media screen and (min-width: 90em){
            margin-top: 0;
        }
    }
}



.section4 {
    margin-top: 20%;
    clear: both;

    @media screen and (min-width: 48em){
        margin-top: 65%;
        width: 100vw;
        height: 640px;
    }
    @media screen and (min-width: 64em){
        margin-top: 64%;
    }
    @media screen and (min-width: 90em){
        margin-top: 48%;
    }
    @media screen and (min-width: 125em){
        margin-top: 50%;
    }
    @media screen and (min-width: 160em){
        margin-top: 54%;
    }
}

.grineerEarth {
    display: none;

    @media screen and (min-width: 48em){
        display: inline;
        float: right;
        position: absolute;
        margin-top: 34%;
        right: 0;
        z-index: 2;
        width: 84%;
    }
    @media screen and (min-width: 64em){
        margin-top: 29%;
        width: 83%;
    }
    @media screen and (min-width: 77em){
        margin-top: 32%;
    }
    @media screen and (min-width: 90em){
        margin-top: 20%;
        width: 80%;
    }
    @media screen and (min-width: 125em){
        margin-top: 7%;
        width: 62%;
    }
    @media screen and (min-width: 160em){
        margin-top: 9%;
    }

    img{
        @media screen and (min-width: 48em){
            width: inherit;
            float: right;
        }
        @media screen and (min-width: 125em){
            width: 100%;
            float: right;
            margin-top: 20%;
        }
    }
}

.ships {
    display: none;

    @media screen and (min-width: 48em){
        display: block;
        position: absolute;
        width: 100%;
    }

    img{
        @media screen and (min-width: 48em){
            width: inherit;
        }
    }
}

.shipLight {
    display: none;

    @media screen and (min-width: 48em){
        display: block;
        width: 100%;
        z-index: 2;
        margin-top: 5%;
        overflow:hidden;
    }
    @media screen and (min-width: 125em){
        margin-top: -2%;
        overflow: hidden;
    }

    img{
        @media screen and (min-width: 48em){
            width: inherit;
        }
        @media screen and (min-width: 64em){
            margin-top: -4%;
            margin-left: 4%;
        }
        @media screen and (min-width: 77em){
            margin-left: 3%;
        }
        @media screen and (min-width: 90em){
            margin-left: 6%;
        }
        @media screen and (min-width: 125em){
            margin-left: 7%;
        }
    }
}

.earth {
    margin-top: 10%;

    @media screen and (min-width: 48em){
        display: none
    }

    img {
        width: 100%;
        float: right;
    }
}

.blueSky,.grasshopper,.lisetSmall,.shipLight{
    position: absolute;
}

.blueSky{
    @media screen and (min-width: 48em){
        margin-top: 18%;
        margin-left: 32%;
        width: 49%;
    }
    @media screen and (min-width: 64em){
        margin-top: 14%;
        margin-left: 36%;
    }
    @media screen and (min-width: 77em){
        margin-left: 35%;
    }
    @media screen and (min-width: 90em){
        margin-left: 38%;
    }
    @media screen and (min-width: 125em){
        margin-left: 42%;
        margin-top: 7%;
    }
}

.grasshopper{
    @media screen and (min-width: 48em){
        margin-top: 35%;
        margin-left: 13%;
        width: 64%;
        z-index: 3;
    }
    @media screen and (min-width: 64em){
        margin-top: 31%;
        margin-left: 17%;
    }
    @media screen and (min-width: 77em){
        margin-left: 16%;
    }
    @media screen and (min-width: 90em){
        margin-left: 19%;
    }
    @media screen and (min-width: 125em){
        margin-left: 23%;
        margin-top: 26%;
    }
}

.lisetSmall{
    @media screen and (min-width: 48em){
        margin-left: 46%;
        width: 46%;
        margin-top: 48%;
        z-index: 3;
    }
    @media screen and (min-width: 64em){
        margin-left: 50%;
        margin-top: 45%;
    }
    @media screen and (min-width: 77em){
        margin-left: 49%;
    }
    @media screen and (min-width: 90em){
        margin-left: 52%;
    }
    @media screen and (min-width: 125em){
        margin-left: 53%;
        margin-top: 39%;
    }

    img{
        @media screen and (min-width: 48em){
            width: inherit;
        }
    }
}

.section5 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 450px;
    clear: both;
    height: 82vh;

    @media screen and (min-width: 23.4375em){
        margin-top: 455px;
    }
    @media screen and (min-width: 26.5625em){
        margin-top: 500px;
    }
    @media screen and (min-width: 36em){
        margin-top: 610px;
    }
    @media screen and (min-width: 48em){
        margin-top: 0%;
        background: none;
        background-size: auto;
        height: auto;
    }
    @media screen and (min-width: 56em){
        margin-top: 24%;
    }
    @media screen and (min-width: 64em){
        margin-top: 15%;
    }
    @media screen and (min-width: 77em){
        margin-top: 27%;
    }
    @media screen and (min-width: 90em){
        margin-top: 38%;
    }
    @media screen and (min-width: 160em){
        margin-top: 48%;
    }
    @media screen and (min-width: 215em){
        margin-top: 55%;
    }

    .title{
        @media screen and (min-width: 48em){
            position: absolute;
            float: right;
            right: 0;
        }
    }

    .transitionBot{
        @media screen and (min-width: 48em){
            margin-top: 78%;
        }
        @media screen and (min-width: 56em){
            margin-top: 65%;
        }

        @media screen and (min-width: 64em){
            margin-top: 57%;
        }

        @media screen and (min-width: 77em){
            margin-top: 48%;
        }

        @media screen and (min-width: 90em){
            margin-top: 57%;
        }

        @media screen and (min-width: 107.5em){
            margin-top: 43%;
        }

        @media screen and (min-width: 125em){
            margin-top: 44%;
        }

        @media screen and (min-width: 142.5em){
            margin-top: 38%;
        }

        @media screen and (min-width: 160em){
            margin-top: 45%;
        }

        @media screen and (min-width: 215em){
            margin-top: 25%;
        }

        @media screen and (min-width: 240em){
            margin-top: 17%;
        }
    }
}

.arsenal {
    display: none;

    @media screen and (min-width: 48em){
        display: block;
        z-index: -1;
        background-color: #000;
        width: 100vw;
        overflow-x: hidden;

        video {
            height: 643px;
        }
    }
    @media screen and (min-width: 90em){
        video {
            height: 860px;
        }
    }
    @media screen and (min-width: 160em){
        video {
            height: 1110px;
        }
    }
}


.section6 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 85vh;
    clear:both;
    position: relative;

    @media screen and (min-width: 36em){
        height: 643px;
        overflow: hidden;
        padding-top: 2%;
    }
    @media screen and (min-width: 90em){
        height: 860px;
    }
    @media screen and (min-width: 125em){
        height: 1030px;
    }
    @media screen and (min-width: 160em){
        height: 1400px;
    }
    @media screen and (min-width: 240em){
        height: 2000px;
    }

    p{
        @media screen and (min-width: 36em){
            text-shadow: 2px 2px 2px #000;
        }
    }
}

footer {
    z-index: 2;
    position: relative;
    bottom: 0;
    background: #000;
    padding: 6% 0;
    width: 100%;
    font-size: 12px;
    color: #fff;

    .footerImgs {
        display: flex;
        flex-direction: column;
        margin-top: -20px;

        @media screen and (min-width: 56em){
            margin-left: 6%;
            width: 45%;
        }
        @media screen and (min-width: 64em){
            margin-left: 12%;
            width: 45%;
        }
        @media screen and (min-width: 125em){
            margin-left: 24%;
            width: 45%;
        }

        img {
            top: 0;
            transform: inherit;
            margin-right: 2px;
        }

        .whiteIcon {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(165deg) brightness(102%) contrast(100%)
        }

        img {
            position: relative;
            margin: 0px 2.5px;
        }

        .logosTop {
            img {
                margin-right:5px;
            }
        }

        @media (max-width: 550px) {
            .logoFlexContainer {
                margin-bottom: 20px;
            }
        }
    }

    small {
        margin-bottom: 20px;
        display: block;

        @media screen and (min-width: 48em){
            margin-left: 4%;
        }
        @media screen and (min-width: 56em){
            margin-left: 0;
        }
        @media screen and (min-width: 64em){
            margin-left: 0;
        }
        @media screen and (min-width: 77em){
            font-size: 13px;
        }
        @media screen and (min-width: 125em){
            margin-left: 0%;
        }
    }
}

.transitionBot2 {
    display: none;

    @media screen and (min-width: 36em){
        display: block;
        position: absolute;
        width: 100%;
        bottom: -17%;
    }
    @media screen and (min-width: 64em){
        bottom: -26%;
    }
    @media screen and (min-width: 77em){
        bottom: -32%;
    }
    @media screen and (min-width: 90em){
        bottom: -26%;
    }
    @media screen and (min-width: 120em){
        bottom: -34%;
    }
    @media screen and (min-width: 125em){
        bottom: -31%;
    }
    @media screen and (min-width: 160em){
        bottom: -20%;
    }
    @media screen and (min-width: 215em){
        bottom: -30%;
    }

    img{
        @media screen and (min-width: 36em){
            width: inherit;
        }
    }
}

@media screen and (min-width: 48em){

    .transitionTop
    {
        display: block;
    }

    .section6 .title
    {
        position: absolute;
    }

    .six.columns {
        width: 50%;
    }

    .footerImgs {
        width: 48%;
        float: left;
    }

    .terms {
        float: left;
        width: 52%;
    }
}

@media screen and (min-width: 56em){

    .terms
    {
        width: 43%;
    }
}

@media screen and (min-width: 64em){

    .six.columns {
        width: 41%;
    }

    .terms {
        width: 35%;
    }
}

@media screen and (min-width: 125em){

    .six.columns {
        width: 32%;
    }

    .terms
    {
        width: 21%;
    }
}

@media (min-width: 550px) and (max-width: 750px){
    .six.columns {
        width: 62% !important;
    }

    .five.columns {
        width: 34% !important;
    }
}
/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-list {
        position: relative;

        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }
        &.dragging {
            cursor: hand;
        }
        .slick-track,.slick-list{
            //@include transform(translate3d(0,0,0));
            transform: translate3d(0,0,0);
        }

        .slick-track {
            position: relative;
            top: 0;
            left: 0;

            display: block;
            margin-left: auto;
            margin-right: auto;
            &:before,&:after{
                display: table;
                content: '';
            }
            &:after{
                clear:both;
            }

            .slick-slide {
                //display: none;
                float: left;

                height: 100%;
                min-height: 1px;

                img {
                    display: block;
                }
            }
        }
    }
}

.contentHeaderContain {
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    margin-top: 40px;

    h2,p{
        color: $pcRed;
        margin: 0 auto;
        margin-top: -9px;
        margin-bottom: 40px;
        font-size: 25px;
        text-transform: uppercase;
        text-align: center;
    }

    h2 {
        margin-top: -18px;
        font-size: 25px;
    }

    p{
        margin-top: -9px;
        font-size: 15px;
    }

    .contentHeaderLines{
        margin-top: 25px;
        width:34%;
        float: left;
        height: 3px;
        margin: 0 auto;
        border-top: 1px solid $pcRed;
        border-bottom: 1px solid $pcRed;
    }

    .contentFooterLines {
        margin-top:30px;
        width:44%;
        float: left;
    }

    .contentLineRight {
        float: right;
    }

    .lotusLogo {
        height: 48px;
        width: 83px;
        margin: 0 auto;
        margin-top: -33px;
        margin-bottom: 50px;
        background-repeat: no-repeat;
        background-image: url('../images/redLotus.png');
    }
}

.slick-loading .slick-track,.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-initialized .slick-slide {
    display: block;
}

// Temp
$duviri-desktop-breakpoint: 64em;

.section1.duviri-paradox {

    @media screen and (min-width: 48em ){
        padding: 32% 0px 0px;
        justify-content: unset;
        height: unset !important;
    }

    .logo {
        //sizing borrowed from `.logoSlogan.static img.long`
        margin: 50vh auto 20px;
        width: 80%;

        @media screen and (min-width: 48em){
            width: 50%;
            margin-top: 0;
        }

        @media screen and (min-width: $duviri-desktop-breakpoint ){
            width: 494px;
            margin-bottom: 44px;
        }

        a {
            display: block;
        }
        img {
            display: block;
            width: 100%;
        }
    };
}
// Temp Duviri Paradox Gameplay section
#duviri-gameplay {
    width: 100vw;
    //display: flex;
    //justify-content: center;
    margin-top: 40px;
    .header {
        margin-bottom: 40px;
        padding: 0 0 40px;
        position: relative;
        width: 80%;
        margin-left: 10%;
        color: white;

        @media screen and (min-width: 48em){
            margin-left: auto;
            margin-right: auto;
            width: 600px;
        }
        @media screen and (min-width: $duviri-desktop-breakpoint){
            // de-facto column-to-layout point
            width: 820px;
        }
        @media screen and (min-width: 90em){
            width: 944px;
        }

        &> :last-child { margin-bottom: 0; }

        &::after {
            content: "";
            border-top: 1px solid #574D38;
            width: 100%;
            height: 0;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin: 0;
            transform: translate( -50%, 0 );

            @media screen and (min-width: $duviri-desktop-breakpoint){
                width: 72%;
            }

         }


        .banner {
            margin-bottom: 8px;
            .h3 {
                color: white;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 28px;
                line-height: 1.3;
                text-align: center;
            }
        }
        &> .title {
            display: block;
            width: auto;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 1.15;

            text-transform: uppercase;

            color: #E4C57C;
            text-align: center;
            &+p {
                margin-top: 35px;
            }
        }
        p {
            text-align: center;
        }

    }

    .sublayout {
        // responsive widths cribbed from `.section2 .title`, `.title`, and `.videoPlayer`
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: 10%;

        @media screen and (min-width: 36em){
        }

        @media screen and (min-width: 48em){
            width: 600px;
            margin: 0 auto;
        }
        @media screen and (min-width: $duviri-desktop-breakpoint){
            // de-facto column-to-layout point
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 820px;
        }
        @media screen and (min-width: 90em){
            width: 1065px;
        }
        @media screen and (min-width: 120em){
            width: 1519px;
        }
        @media screen and (min-width: 125em){
            width: 1570px;
        }
        @media screen and (min-width: 160em){
            width: 2050px;
        }


        figure {
            margin: 0;
            margin-bottom: 20px;

            @media screen and (min-width: $duviri-desktop-breakpoint){
                margin-bottom: 0;
                // de-facto column-to-layout point
                width: percentage( math.div( 536, 1223 ) );
            }

            img {
                width: 100%;
                display: block;
            }
        }

        .content {
            padding: 0;

            @media screen and (min-width: $duviri-desktop-breakpoint){
                // de-facto column-to-layout point
                width: percentage( math.div( 547 , 1223) );
            }
            .copy {
                color: white;
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
            }

            .title {
                width: auto;
                margin: 0 0 12px;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 1.15;

                text-transform: uppercase;

                color: #E4C57C;

                &+ p {
                    margin-top: 12px;
                }
            }

            p {
                margin-top: 16px;
                span {
                    color: #E4C57C;
                    font-weight: 700;
                }

            }
        }

    }

    &+ section[style] {
        display: none !important;
        @media screen and (min-width: 48em){
            display: flex !important;
        }
    }

    .haveAccount {
        font-size: 12px;
    }
}

.duviri-paradox-section-layout {
    .sublayout {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 67vw min-content;

        @media screen and ( min-width: 768px ){
            grid-template-rows: 29.167vw min-content;
        }

        @media screen and ( min-width: 1600px ){
            grid-template-rows: calc( 29.167vw + 2vh ) min-content;
        }


        // 440/1440
    }
    figure.key-art {
        margin: 0;
        width: 100%;
        grid-row: 1/2;
        img {
            display: block;
            width: 100%;
        }
    }
    .content {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-row: 2/3;

        .branding {
            max-width: 300px;
            @media screen and ( min-width: 768px ){
                max-width: 50%;
            }
            @media screen and ( min-width: 1000px ){
                max-width: 490px;
            }

            @media screen and ( min-width: 1600px ){}

            a, img { display: block; }
            img { width: 100%; }
        }
        .ctas {
            display: flex;
            justify-content: center;
            margin-top: 24px;
            margin-bottom: 20px;
        }
        .caption {}
        .copy {
            margin-top: 30px;
            text-align: center;
            max-width: 70%;
            @media screen and ( min-width: 768px ){
            }
            @media screen and ( min-width: 1000px ){
                max-width: 700px;
            }

            @media screen and ( min-width: 1600px ){}

        }
        .control {
            display: flex;
            justify-content: center;
        }

        a.haveAccount {
            margin: 0;
        }
    }
}

// Demo Callout Section
$COLOR_DOWNLOAD_TITLE: #F6E3AB;
$IMAGE_DIR: '/images/game/download/';
@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

// 1999 DEMO SECTION

#demo-callout{
    --color-text: #{$COLOR_INVERTED_TEXT};
    --color-title: #{$COLOR_DOWNLOAD_TITLE};
    color: #{$COLOR_INVERTED_TEXT};
    background-color: $COLOR_BLACK;
}
.demoBackground{
    @include FULL_COVER;
    background: top / cover no-repeat url(#{$IMAGE_DIR}1999-bg-callout-motorcycle.jpg);

    @include FULL_COVER_WIDE_CAP;
    @include breakpoint-wide-up {
        mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    }

    @include breakpoint-mobile-only{

        background-position: -300px;

        height: 60%;

        &:after {
            content: "";
            @include FULL_COVER;
            background: linear-gradient(to top, black, transparent 30%);
        }
    }
    
}

.demoGrid {
    position: relative;
    padding: $SPACE_LARGE $SPACE_LARGE 80px;
    min-height: 680px;
    max-width: 600px;
    margin: 0 auto;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @include breakpoint-tablet-up {
        max-height: 810px;
    }

    @include breakpoint-desktop-up {
        height: 810px;
    }

    &-title {
        color: var(--color-title);
        @include TYPOGRAPHY_H1_ALTERNATE;
    }
    &-description {
        font-size: $FONT_SIZE_PARAGRAPH_LARGE;
        margin-block-end: 1rem !important;
        margin-block-start: 1rem !important;
    }
    .ButtonGroup{
        margin-block-start: 1.5rem;

        .Button-label{
            letter-spacing: normal;
        }
    }
    &-requirements {
        margin-top: 1rem;
    }

    // Mobile/tablet adjustment
    @include breakpoint-mobile-only{
        justify-content: flex-end;
    }

    @include breakpoint-tablet-only{
        padding-right: 0px;
        padding-left: 0px;
        width: 345px;
        
    }
}

.bottom-line{
    width: 100%;
    height: 1px;
    background-color: white;
}

@import '../_gdpr';
@import "../account/_signup";
@import "../account/_lightbox_forms";
@import "../_fixed_footer";
